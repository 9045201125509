import store from '../store/store';

export function canAccess(path, role) {
  if (role === undefined || path === undefined) return false;
  if (role !== 'Registrar' && role !== 'President') return false;
  return true;
}

export function checkNavigationGuards(to, from, next) {
  if (to.name !== 'Login' && !store.state.isAuthenticated)
    next({ name: 'Login' });
  else if (
    to.name == 'Login' &&
    store.state.isAuthenticated &&
    (store.state.user.role == 'Registrar' ||
      store.state.user.role == 'President')
  )
    next({ name: 'Main' });
  else next();
}
