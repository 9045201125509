import Vue from 'vue';
import router from '../src/router/router';
import store from '../src/store/store';
import axios from 'axios';
import { Form } from 'vform';
import App from './App.vue';
import './common/dateFilters.js';
import { checkNavigationGuards } from './middlewares/auth';

Vue.config.productionTip = false;
window.axios = axios;
window.Form = Form;

axios.defaults.withCredentials = true;
axios.defaults.baseURL = 'https://api.chedcaraga.ph/';

router.beforeEach(checkNavigationGuards);

new Vue({
  el: '#app',
  router,
  store,
  template: '<App/>',
  components: {
    App,
  },
});
